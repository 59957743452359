import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Paragraph, Paragraphs } from "../components/atoms/typography";
import { DesktopOnly, MobileOnly } from "../components/gls/responsive-layout";
import { VerticalSpacer } from "../components/gls/spacer";
import { Vertical } from "../components/gls/vertical";
import { Page } from "../components/templates/page/page";
import { InfoList, InfoListItem } from "../components/templates/page/panel/info-panel";
import { Section } from "../components/templates/page/section/section";
import { SectionHero, SectionHeroImageCss } from "../components/templates/page/section/section-hero";
import { SectionInfo, SectionInfoQuoteText } from "../components/templates/page/section/section-info";
import { SectionIntroHeading, SectionIntroLayout } from "../components/templates/page/section/section-intro";

export const HydrogenForMobilityPage = () => (
  <Page title={"Hydrogen For Mobility"}>
    <SectionHero
      title={"Powering the next generation of commercial vehicles"}
      image={
        <StaticImage
          src={`../assets/images/hero/hero-hydrogen-for-mobility.png`}
          style={SectionHeroImageCss}
          placeholder="dominantColor"
          alt=""
        />
      }
    />
    <Section>
      <SectionIntroLayout
        left={<SectionIntroHeading maxWidth={500}>Hydrogen for mobility</SectionIntroHeading>}
        right={
          <Vertical space={"xl"}>
            <Paragraphs>
              <Paragraph maxWidth={600}>
                Hydrogen fuelled commercial vehicles will contribute to the transition to clean energy on Australian
                roads. The local production of hydrogen will bring both environmentally and economically sustainable
                benefits to Australia.
              </Paragraph>
              <Paragraph maxWidth={600}>
                H2FA is proud to play its part in realising the potential of hydrogen as a key enabler of a decarbonised
                energy system - the way of the future.
              </Paragraph>
              <Paragraph maxWidth={600}>
                We are proud to be making green hydrogen (for zero emission) mobility commercially available - right
                here in Victoria.
              </Paragraph>
              <Paragraph maxWidth={600}>
                Until now the lack of infrastructure has been the biggest impediment to hydrogen mobility. H2FA is
                providing that infrastructure.
              </Paragraph>
              <Paragraph maxWidth={600}>
                Given our ability to compress hydrogen and pack a large amount into a small space, heavy vehicles can
                take advantage of such significant energy density to travel long distances without the need to refuel.
              </Paragraph>
              <Paragraph maxWidth={600}>
                Not only is the cost price of green hydrogen falling, it is predicted to fall below that of diesel fuel
                in the near future. The environmentally safer option will also become commercially preferable.
              </Paragraph>
            </Paragraphs>
            <DesktopOnly>
              <InfoList>
                <InfoListItem>Smooth & silent propulsion</InfoListItem>
                <InfoListItem>Electrifying the transport sector</InfoListItem>
                <InfoListItem>Rapid refuelling</InfoListItem>
              </InfoList>
            </DesktopOnly>
          </Vertical>
        }
      />
    </Section>
    <SectionInfo>
      <SectionInfoQuoteText
        quote={
          "The cost of green hydrogen it’s coming down real fast, coming down faster than anyone expects. I wrote a book on this last year, the book is already out of date, just because of how fast the cost…. is falling…"
        }
        author={"Marco Alverá, CEO, SNAM, EAH Podcast"}
      />
    </SectionInfo>
    <MobileOnly>
      <Section>
        <InfoList>
          <InfoListItem>Smooth & silent propulsion</InfoListItem>
          <InfoListItem>Electrifying the transport sector</InfoListItem>
          <InfoListItem>Rapid refuelling</InfoListItem>
        </InfoList>
      </Section>
    </MobileOnly>
    <StaticImage
      src={`../assets/images/nature/water-sand.png`}
      layout="fullWidth"
      placeholder="blurred"
      style={{
        maxHeight: 700,
      }}
      objectFit={"fill"}
      alt=""
    />
    <VerticalSpacer space={"xl"} />
  </Page>
);

export default HydrogenForMobilityPage;
